@import url('https://fonts.googleapis.com/css?family=EB+Garamond|Roboto:300,400,500,700&subset=cyrillic,latin-ext');

$blue: #002c50;
$dark-blue: #00213c;
$light-blue: #073c65;
$lighter-blue: #257bb9;
$orange: #ffc600;
$brownish: #5e4900;
$black: #44525e;
$almost-white: #cdddeb;

body{
    font-family: 'Roboto', sans-serif;
}

a{

    &:hover{
        color: $lighter-blue;
    }
    color: $lighter-blue;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.btn{
    background-color: $orange;
    border-radius: 0;
    color: $brownish;
    font-weight: 500;
    margin-top: 10px;
    font-family: 'Roboto';

    &:hover{
        color: $brownish;
        background-color: #ffa507;
    }

    &:focus{
        box-shadow: 0 0 10px #000;
    }

    &:active,
    &:focus{
        outline: 0;
        color:$brownish;
    }

}

.container{
    max-width: 1100px;
}

header{
    .top{
        position: relative;
        z-index: 10;
        .row{
            .col{
                padding: 0;
            }
        }
        a{

            &.login-link{
                background-color: $orange;
                background-image: url('../images/html/log-in-ico.svg');
                background-position: 15px center;
                background-repeat: no-repeat;
                color: $brownish;
                padding: 4px 22px 3px 35px;
            }
            color: $almost-white;
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            padding: 4px 9px 3px 9px;
        }
        background-color: $dark-blue;
    }

    .login{
        margin-left: 30px;
        .login-wrap{
            position: absolute;
            right: 1px;
            top: 100%;
            background-color: #FFF;
            z-index: 1;
            padding: 20px;
            transform: scaleY(0);
            opacity: 0;
            transition: opacity ease-in-out .2s 0s, transform ease-in-out .2s .05s;
            transform-origin: top left;

            form{
                label{
                    display: block;
                    font-size: 13px;
                    margin-bottom: 5px;
                }
                ::placeholder{
                    opacity: 1;
                    color: rgba(68, 82, 94, 0.5);
                }
                input{
                    display: block;

                    &[type="email"],
                    &[type="password"]{
                        padding: 5px 10px;
                        font-size: 14px;
                    }

                    &[type="email"]{
                        margin-bottom: 10px;
                    }

                    &[type="submit"]{
                        width: 100%;
                        cursor: pointer;
                        font-weight: 500;
                    }
                }

                a{
                    color:$lighter-blue;
                    font-weight: 300;
                }

                .forgot-pass{
                    font-size: 13px;
                    padding: 0;
                }

                p{
                    margin-top: 10px;
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 300;

                    a{
                        font-size: inherit;
                    }
                }
            }
        }

        &.open-form{
            .login-wrap{
                transform: scaleY(1);
                opacity: 1;
                transition: opacity ease-in-out .2s .05s, transform ease-in-out .2s 0s;
            }

            .overlay{
                transform: scale(1);
            }
        }

        .overlay{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: scale(0);
            transition: all .2s;
        }
    }

    .bottom{
        a{
            color: #FFF;
            font-size: 15px;
            font-weight: 500;
        }

        .navigation-wrap{
            .navbar{

                .nav-link{
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    padding: 25px 16px;
                    border-bottom: 3px solid transparent;

                    &:hover{
                        background-color: $light-blue;
                    }
                    &.active{
                        background-color: $light-blue;
                        border-color: $lighter-blue;

                    }
                    transition:all .2s ease-in-out;

                }

                .nav-item{
                    position: relative;
                    &:hover,
                    &.active{
                        .dropdown{
                            transform: scaleY(1);
                            opacity: 1;
                            transition: opacity ease-in-out .2s .05s, transform ease-in-out .2s 0s;

                        }
                    }
                }

                .dropdown{
                    background-color: $blue;
                    .nav-link{

                        &:hover,
                        &.active{
                            background-color: $light-blue;
                        }
                        background-color: $blue;
                        border-bottom:0;
                        display: block;
                        justify-content: flex-start;
                        line-height: 1.2em;
                        min-width: 175px;
                        padding: 15px 20px;
                        padding-left: 20;
                        padding-right: 20px;
                    }
                    max-width: 250px;
                    opacity: 0;
                    position: absolute;
                    top: 100%;
                    transform: scaleY(0);
                    transform-origin: top left;
                    transition: opacity ease-in-out .2s 0s, transform ease-in-out .2s 0.05s;
                }
                float: right;
                padding: 0;
            }
            align-self: flex-end;
            padding: 0;
        }
    }

    .logo-wrap{

        a{
            top: 100%;
            position: absolute;
            transform: translateY(-50%);
        }
        min-width: 115px;
        max-width: 115px;
        padding: 0;
        position: relative;
    }

    .title-wrap{
        align-items: center;
        color: $almost-white;
        display: flex;
        font-size: 16px;
        line-height: 1.2em;
        max-width: 180px;
        min-width: 180px;
        padding: 0;
        padding-left: 9px;

    }

    &+section:not(.carousel){
        margin-top: 100px;
    }

    background-color: $blue;
    z-index: 100;
}

.bnrs{
    .bnr{
        min-height: 400px;
        display: flex;
        align-items: center;
        background-position: center center;
        background-size: cover;
        width: 100%;

        .content{
            font-family: 'EB Garamond';
            color: $orange;
            font-size: 24px;
            line-height: 2.3em;
            padding-left: 0;
            padding-right: 7%;
        }
    }
}

.news{
    padding: 0 15px;

    article{
        padding: 30px 0;
        border-bottom: 1px solid #d5d5d5;
        .img{
            max-width: 265px;
            min-width: 265px;
            padding-left: 0;
            text-align: center;
            padding-right: 30px;
        }
        .content{
            padding: 0;
            font-size: 16px;
            h3{
                font-family: 'EB Garamond';
                font-size: 36px;
            }

            .text{
                height: 2.8em;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .btn{
                margin-top: 20px;
            }
        }

        &:first-of-type{
            padding-top: 0;
        }

        &:last-child{
            border-bottom: 0;
        }
    }
}

.partners{
    .top{
        text-align: center;
        display: flex;
        position: relative;
        font-size: 18px;


        span{
            display: block;
            padding: 0 10px;
            white-space: nowrap;
            font-family: 'EB Garamond';
        }

        &:before,
        &:after{
            content: '';
            display: block;
            height: 1px;
            border-top: 1px solid #d5d5d5;
            width: 50%;
            position: relative;
            top: 15px
        }

        margin-bottom: 20px;
    }

    .partner-wrap{
        max-height: 110px;
        overflow: hidden;

        .owl-item{
            text-align: center;
            height: 110px;

            .partner-item{
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 140px;
                width: 100%;
                height: 100%;
                display: none;

                img{
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    .container{
        max-width: 1130px;
    }

    margin-bottom: 45px;
}

footer{
    .container{
        max-width: 1130px;
    }
    .top{
        background-color: $blue;

        .nav-link{
            float: left;
            padding: 20px 15px;

            &:hover{
                background-color: $light-blue;
            }
        }
        a{
            color: #FFF;
        }

        .soc{
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .soc-item{
                margin-left: 15px;
                width: 40px;
                height: 40px;
                background-color: $lighter-blue;
                text-align: center;
                line-height: 40px;
                display: block;

                img{
                    vertical-align: middle;
                }

                &:hover{
                    background-color: $light-blue
                }
            }
        }
    }

    .bottom{
        font-size: 14px;
        line-height: 1.2em;

        a{
            color: #fff;
        }
        .left{
            padding-left: 15px;
            padding-top: 20px;
            padding-bottom: 57px;

            .contacts{
                td:first-child{
                    padding-right: 20px;
                }
                td{
                    padding: 5px 0;
                }
                tr:first-child{
                    td{
                        padding-bottom: 15px;
                    }
                }
            }
        }

        .right{
            padding-top: 20px;
            padding-bottom: 57px;
        }

        .copy{
            font-size: 12px;
            margin-top: 80px;
        }

        .bold{
            font-size: 18px;
            font-weight: 500;
        }

        .contact-us{
            position: fixed;
            bottom: 0;
            z-index: 100;

            a{
                font-size: 16px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                background-image: url('../images/html/mail-ico.svg');
                background-repeat: no-repeat;
                background-position: right 12px top 16px;
                transition: all .2s ease-in-out;
                background-color: $lighter-blue;
                padding: 15px 45px 10px 20px;
                text-decoration: none;
                display: block;
                height: 100%;

                &:hover{
                    background-color: $light-blue;
                }
            }
        }

        .larger-map{
            font-size: 12px;
        }
    }

    background-color: $dark-blue;
    color: #FFF;
}

#map{
    height: 230px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.main-content{
    padding-bottom: 80px;
    padding-top: 70px;

    .text{
        color: $black;
        img{
            margin-right: 20px;
            margin-bottom: 5px;
            max-width: 100%;
        }
        ol{
            padding-left: 0;
            counter-reset: cnt;
            li{
                counter-increment: cnt;
                list-style: none;
                text-indent: -20px;
                padding-left: 22px;
                margin-bottom: 5px;

                &:before{
                    content: counter(cnt)'. ';
                    font-weight: 500;
                    color: #165c8f;
                    font-size: 14px;
                    padding-right: 5px;
                }
            }
        }

        ul{
            padding-left: 0;
            li{
                list-style: none;
                text-indent: -18px;
                padding-left: 22px;
                margin-bottom: 5px;

                &:before{
                    content: '\2022 ';
                    font-weight: 500;
                    color: #165c8f;
                    font-size: 23px;
                    line-height: 16px;
                    position: relative;
                    top: 3px;
                    padding-right: 10px;
                }
            }
        }

        table{
            font-size: 14px;
            thead{
                tr{
                    th, td{
                        padding: 7px 18px;
                    }
                    border-bottom: 2px solid $lighter-blue;
                }
            }

            tbody{
                tr{
                    td{
                        padding: 13px 18px;
                        min-width: 120px;
                    }

                    &:last-child{
                        border-bottom: 0;
                    }
                    border-bottom: 2px solid #d5d5d5;
                }
            }
        }
    }

}

.left-content{
    max-width: 225px;
    min-width: 225px;
    margin-right: 10px;
    .sub-navigation{
        background-color: #f5f6f7;
        margin-bottom: 30px;

        .nav-item{
            background-color: #f5f6f7;
            border-top: 2px solid #d5d5d5;

            &:first-child{
                border-top: 0;
            }

            &.active{
                border-top: 0;

                &>.nav-link{
                    background-color: $orange;
                }
            }
        }

        a.active{
            background-color: $orange;

        }

        .nav-link{
            color: $black;
            font-weight: 500;
            padding: 14px 20px;

            &.active{
                &+.nav-item{
                    border-top: 0;
                }
            }
        }

        .sub-menu{
            padding: 15px 0;

            .nav-link{
                padding: 8px;
                color: $lighter-blue;
                font-size: 14px;
                font-weight: 400;
                padding-left: 35px;

                &:hover{
                    text-decoration: underline;
                }

                &.active{
                    background-color: inherit;
                    color: #0f3658;

                }
            }
        }
    }
}

.actions{
    margin-top: 20px;
    border-top: 1px solid #d5d5d5;
    padding-top: 12px;

    .soc-actions{
        padding-top: 18px;
        .soc-wrap{
            float: left;
            margin-left: 3px;

            &.draugiem{
                width: 70px;
                height: 20px;
                background-color: #ff6600;
                border-radius: 3px;
                display: none;
            }

            &.facebook{
                line-height: 0;
            }
        }
    }

    &:after{
        display: block;
        content: '';
        clear: both;
    }
}

h1{
    font-size: 54px;
    color: $blue;
    font-family: "EB Garamond";
    margin-bottom: 20px;
}

h2{
    @extend h1;
    font-size: 37px;
}

h3{
    @extend h1;
    font-size: 24px;
}

.table-wrap{
    max-width: 100%;
    overflow-x: auto;
}

.contact-wrap{
    .top{
        border-bottom: 1px solid #d5d5d5;
        padding-bottom: 25px;
        margin-bottom: 45px;
    }

    .soc{
        span{
            display: block;
            margin-bottom: 10px;
        }

        .soc-item{
            background-color: #eeeeee;
            display: block;
            width: 40px;
            height: 40px;
            float: left;
            margin-right: 5px;
            text-align: center;
            line-height: 40px;
            display: block;

            &:hover{
                background-color: #cccccc;
            }

            img{
                vertical-align: middle;
            }
        }
    }

    .phone{
        background-image: url('../images/html/phone.svg');
        background-repeat: no-repeat;
        background-position: 0 0;
        padding-left: 30px;
        margin-bottom: 15px;

        a{
            font-size: 20px;
            color: $black;
            font-weight: 500;
        }
    }
    .emails{
        background-image: url('../images/html/mail-ico-blue.svg');
        background-repeat: no-repeat;
        background-position: 0 6px;
        padding-left: 30px;
    }

    .bottom{
        .requisites{
            padding-top: 10px;

            table{
                td{
                    padding: 5px;
                }
                td:first-child{
                    min-width: 130px;
                }
                td:last-child{
                    padding-left: 10px;
                }
            }

            .row{
                margin: 0;
                >.col{
                    padding: 0;
                    border-bottom: 1px solid #d5d5d5;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }

                &:last-child{
                    >.col{
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    form{
        background-color: #f5f6f7;
        padding: 20px;

        label{
            font-size: 13px;
            margin-bottom: 3px;
        }

        input[type="text"]{
            display: block;
            width: 100%;
            margin-bottom: 5px;
            padding-left: 10px;
            height: 40px;
            border: 1px solid #d5d5d5;
            font-size: 14px;
        }

        input[type="email"]{
            @extend input[type="text"];
        }

        textarea{
            @extend input[type="text"];
            height: 220px;
            padding-top: 10px;
        }

        ::placeholder{
            opacity: 1;
            color: rgba(68, 82, 94, 0.5);
        }

        input[type="submit"]{
            min-width: 200px;
            margin-top: 20px;
        }

        .errors p{
            color: #AE1717;
            margin-bottom: 5px;
            font-size: 14px;
        }

        .success{
            text-align: center;
            color: #28c700;
            font-weight: 500;
            margin-top: 16px;
        }
    }
}

#div_MainTitle{
    min-height: unset !important;
}
#div_TopLogo>div{
    height: 75px;
}